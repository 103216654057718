/* Author: herrlich media */
import '@fancyapps/fancybox';
import './plugins/inView';
import 'owl.carousel.es6';

import Swiper, {
    Navigation, Pagination, Controller, Lazy
} from 'swiper';

import {
    TimelineMax, TweenMax, Linear, Power2
} from 'gsap';

import './plugins/turn';
import 'jquery-ui/ui/widgets/slider';

import dataStore from './inc/dataStore';
import browserDetection from './inc/browserDetection';

import observeIntersection from './inc/observeIntersection';

Swiper.use(
    [Navigation, Pagination, Controller, Lazy]
);

const LazyLoad = require('vanilla-lazyload');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    browserDetection();
    // Get all elements on the page
    const allElements = document.querySelectorAll('.headerBg, .img');
    // Regular expression zum Finden von URLs, die mit .jpg oder .png enden, gefolgt von möglichen Query-Parametern
    const regex = /\.(jpg|png)(\?[^"']*)?(["']?\)$)/i;

    // Iterate over each element
    allElements.forEach((element) => {
        const bgImg = window.getComputedStyle(element).backgroundImage;
        if (regex.test(bgImg)) {
            // Die .jpg oder .png Erweiterung mit .jpg.webp oder .png.webp ergänzen
            element.style.backgroundImage = bgImg.replace(regex, '.$1.webp$2$3');
        }
    });

    /* stack ++++++++++++++++++++++++++++++++++++++++++*/
    if (dataStore.stackContainer.length) {
        if (dataStore.isIe === false && dataStore.docWidth > dataStore.touchBreakPoint) {
            dataStore.stackScroll = true;
        }

        stackResize();

        $('.stackNav li').click((e) => {
            e.preventDefault();

            const target = $(e.currentTarget).data('target');
            let targetEl;

            for (let index = 0; index < dataStore.stackArr.length; index++) {
                const element = dataStore.stackArr[index];

                if (target in element.items) {
                    targetEl = element.items[target];
                    break;
                }
            }

            $('html, body').animate({
                scrollTop: targetEl.top - dataStore.headerHeight
            }, 500);
        });
    }

    /* Prozessdiagramm ++++++++++++++++++++++++++++++++++++++++++*/

    setTimeout(() => { singleScrollResize(); }, 500);

    $('.processItem.process').click((e) => {
        e.preventDefault();

        const processItem = $(e.currentTarget);
        const item = processItem.data('process');

        if (!processItem.hasClass('current')) {
            $('.processItem.process.current, .processContent.current').removeClass('current');

            processItem.addClass('current');
            const processContent = $(`.processContent[data-process=${item}]`);

            if (dataStore.singleScrollTrans === true) {
                $('html, body').animate({
                    scrollTop: processContent.offset().top - dataStore.headerHeight - (dataStore.scrollerHeight / 2)
                }, 250);
            } else {
                processContent.addClass('current');
            }
        }
    });

    $('.arrowNav .processLink').click((e) => {
        e.preventDefault();

        const link = $(e.currentTarget);
        const count = $('.processContent').length;

        if (!link.hasClass('disabled')) {
            $('.processLink').removeClass('disabled');

            const current = $('.processContent.current');
            const i = current.index();

            if ($(e.currentTarget).hasClass('processNext')) {
                const next = current.next('.processContent');
                const itemProcess = next.data('process');

                $(`.processItem[data-process=${itemProcess}]`).trigger('click');

                if (i + 2 === count) {
                    link.addClass('disabled');
                }
            } else {
                const prev = current.prev('.processContent');
                const itemProcess = prev.data('process');

                $(`.processItem[data-process=${itemProcess}]`).trigger('click');

                if (i - 1 === 0) {
                    link.addClass('disabled');
                }
            }
        }
    });

    /* Mobile NAV ++++++++++++++++++++++++++++++++++++++++++*/
    $('#navToggle').click(() => {
        const nav = $('#mainnavCon');
        $('#navToggle, #mainnavCon, body').toggleClass('open');
        if (nav.hasClass('open')) {
            setTimeout(() => {
                nav.addClass('overflowScroll');
            }, 300);
        } else {
            nav.removeClass('overflowScroll');
        }
    });

    const $root = $('html, body');

    $('a.btn[href^="#"], #footerNav a[href^="#"],#footerNav a[href*="#"]').click(() => {
        $root.animate({
            scrollTop: $($.attr(this, 'href')).offset().top - dataStore.headerHeight
        }, 500);

        return false;
    });

    $('.logo a').click((e) => {
        e.preventDefault();

        if (window.wp_urls.isHome === parseInt(1, 10)) {
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        } else {
            window.location.href = window.location.origin;
        }
    });

    /* List Bubble Hover ++++++++++++++++++++++++++++++++++++++++++*/

    if ($('.postList').length) {
        $('.postList .postItem').mouseover((e) => {
            const postListTop = $('.postList').offset().top;
            const top = $(e.currentTarget).offset().top - postListTop;

            $('.postItem').removeClass('noLine');

            $('#lineBubble').css({
                transform: `translate(0,${top}px)`,
                '-webkit-transform': `translate(0,${top}px)`,
            }).addClass('vis');

            $(e.currentTarget).prev('.postItem').addClass('noLine');
        });

        $('.postList').mouseout(() => {
            $('#lineBubble').removeClass('vis');
            $('.postItem').removeClass('noLine');
        });
    }

    if ($('.rocketManSVG').length) {
        const jetBubbles = document.getElementsByClassName('jetBubble');
        const rocketManSVG = document.querySelector('.rocketManSVG');
        const star = document.querySelector('.star');
        const astronaut = document.querySelector('.astronaut');
        const starContainer = document.querySelector('.starContainer');

        TweenMax.to(astronaut, 0.05, {
            y: '+=4',
            repeat: -1,
            yoyo: true
        });
        const mainTimeline = new TimelineMax({ repeat: -1 });
        const mainSpeedLinesTimeline = new TimelineMax({ repeat: -1, paused: false });

        mainTimeline.timeScale(6).seek(100);

        TweenMax.staggerTo('.pulse', 0.8, {
            alpha: 0,
            repeat: -1,
            ease: Power2.easeInOut,
            yoyo: false
        }, 0.1);

        createJets(rocketManSVG, star, starContainer, jetBubbles, mainSpeedLinesTimeline, mainTimeline);
    }

    observeIntersection('.servicesContents__stickyContainer', (e, intersected) => {
        if (intersected) {
            // $('.servicesContents__stickyContainer').not(e.target).removeClass('active');
            $(e.target).addClass('active').get(0)
                .style.setProperty('--imgOffset', `${$(e.target).find('img').height() / 2}px`);
        } else {
            $(e.target).removeClass('active');
        }
    }, false, 0, undefined, '-50% 0% -50% 0%');

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    const lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy'
    });

    lazyLoadInstance.update();

    // FANCYBOX
    $('[data-fancybox]').fancybox({
        afterLoad() {
            dataStore.smoothDisabled = true;
        },
        beforeClose() {
            dataStore.smoothDisabled = false;
        }
    });

    /* inview  ++++++++++++++++++++++++++++++++++++++++++*/

    $('.processWrapper').one('inview', (event, visible) => {
        if (visible === true) {
            $(event.currentTarget).addClass('vis');
        }
    });

    $('.svgWrap, .iconWrap').bind('inview', (event, visible) => {
        if (visible === true) {
            $(event.currentTarget).addClass('vis');
        }
    });

    $('.headWrap').one('inview', (event, visible) => {
        if (visible === true) {
            setTimeout(() => {
                $('.headWrap').removeClass('notVis');
            }, 1000);
        }
    });

    $('#campaignWrap').one('inview', (event, visible) => {
        if (visible === true) {
            setTimeout(() => {
                $('#campaignWrap').addClass('vis');
                $('.header').addClass('vis');

                setTimeout(() => {
                    $('.headWrapAnimation').removeClass('notVis');
                }, 1000);

                setTimeout(() => {
                    const scrollDistance = $('.headWrapAnimation').offset().top - dataStore.headerHeight - 100;

                    if (dataStore.docScroll < scrollDistance) {
                        $('html, body').animate({
                            scrollTop: scrollDistance
                        }, 500);
                    }
                }, 3500);
            }, 500);
        }
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll((e) => {
        dataStore.scrollData = e;
        scrollAction();
        // window.requestAnimationFrame(scrollAction);
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    if (dataStore.docWidth < dataStore.touchBreakPoint) {
        dataStore.isMobile = true;
    }

    $(window).trigger('scroll');

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
    }, 250);
}).on('orientationchange', () => {
    resizeViewport();
});

function afterResize() {
    mainNavigation();
    mobileSubmenu();
    createSvgPaths();
    singleScrollResize();
    stackResize();
}

$(window).on('load', () => {
    createSvgPaths();
    stackResize();
    mainNavigation();

    /* Broschüre ++++++++++++++++++++++++++++++++++++++++++*/
    if ($('#flipbook').length) {
        loadflipBook();
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /**** OWL SLIDER ***/

    $('.bigHeader .owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText: [`<img src='${window.wp_urls.template_dir}/assets/img/arrow-left.svg'>`,
            `<img src='${window.wp_urls.template_dir}/assets/img/arrow-right.svg'>`],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });

    $('.imageText .owl-carousel').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });

    new Swiper('#testimonialSwiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        autoHeight: true,
        allowTouchMove: false,
        centeredSlides: false,
        navigation: {
            nextEl: '#testimonials .swiper-button-next',
            prevEl: '#testimonials .swiper-button-prev',
        },
        breakpoints: {
            1024: {
                slidesPerView: 2,
                spaceBetween: 30,
                autoHeight: false,
            },
        },
    });

    // Init slider gallery
    sliderGallery();
});

function mobileSubmenu() {
    if (dataStore.noTouch === false || dataStore.docWidth < dataStore.touchBreakPoint) {
        let mobileSubmenuFlag = true;
        $('.menuPlus').off('touchStart click').on('touchStart click', (e) => {
            if (mobileSubmenuFlag === true) {
                mobileSubmenuFlag = false;
                $(e.currentTarget).toggleClass('active');
                $(e.currentTarget).siblings('.sub-menu').slideToggle(250, 'swing', () => {
                    mobileSubmenuFlag = true;
                });
            }
        });
    }
}

// (() => {
//     let lastTime = 0;
//     const vendors = ['webkit', 'moz'];
//     for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
//         window.requestAnimationFrame = window[`${vendors[x]}RequestAnimationFrame`];
//         window.cancelAnimationFrame = window[`${vendors[x]}CancelAnimationFrame`] ||
//window[`${vendors[x]}CancelRequestAnimationFrame`];
//     }

//     if (!window.requestAnimationFrame) {
//         window.requestAnimationFrame = function(callback, element) {
//             const currTime = new Date().getTime();
//             const timeToCall = Math.max(0, 16 - (currTime - lastTime));
//             const id = window.setTimeout(() => { callback(currTime + timeToCall); },
//                 timeToCall);
//             lastTime = currTime + timeToCall;
//             return id;
//         };
//     }

//     if (!window.cancelAnimationFrame) {
//         window.cancelAnimationFrame = function(id) {
//             clearTimeout(id);
//         };
//     }
// }());

function scrollAction() {
    try {
        dataStore.docScroll = dataStore.scrollData.target.scrollingElement.scrollTop;
    } catch (err) {
        dataStore.docScroll = $(window).scrollTop();
    }

    if (dataStore.docScroll >= 1 && !dataStore.scrollClass) {
        dataStore.scrollClass = true;
        dataStore.header.addClass('scrolled');
    } else if (dataStore.docScroll === 0 && dataStore.scrollClass) {
        dataStore.scrollClass = false;
        dataStore.header.removeClass('scrolled');
    }

    //scrollNavi();

    scrollStacks();

    if (dataStore.singleScroll && dataStore.singleScrollTrans) {
        if (dataStore.docScroll > dataStore.scrollWrapTop - dataStore.headerHeight
        && dataStore.docScroll < (dataStore.scrollWrapTop + dataStore.scrollerDistance - dataStore.headerHeight)) {
            // PROCESSITEM IM SICHTBAREM
            // console.log('PROCESSITEM IM SICHTBAREM');

            // if(!dataStore.scrollFixed) {
            //     // FIX SETZTEN
            //     dataStore.scrollFixed = true;
            //     dataStore.scroller.addClass('fixed');
            // }

            let thisTop;
            let height;
            let thisBottom;
            // let percent;
            let this_;
            dataStore.processContent.each((i, el) => {
                this_ = $(el);
                thisTop = this_.offset().top - dataStore.winHeight;
                height = this_.height();
                thisBottom = thisTop + height;

                // percent = 0;
                // Scrolled within current section
                if (dataStore.docScroll >= thisTop + (dataStore.winHeight - dataStore.scrollerHeight)
                && dataStore.docScroll <= thisBottom + (dataStore.winHeight - dataStore.scrollerHeight)) {
                    $('.processItem.process.current').removeClass('current');
                    $('.processContent.current').removeClass('current');
                    this_.addClass('current');
                    $(`.process[data-process="${this_.data('process')}"]`).addClass('current');
                }

                if (i > 0) {
                    dataStore.firstCurrent = false;
                }
            });
        } else if (dataStore.firstCurrent === false && dataStore.docScroll < dataStore.scrollWrapTop) {
            dataStore.firstCurrent = true;
            $('.processItem.process.current').removeClass('current');
            $('.processContent.current').removeClass('current');
            $(dataStore.processContent[0]).addClass('current');
            $(`.process[data-process="${$(dataStore.processContent[0]).data('process')}"]`).addClass('current');
        }

        // if (dataStore.docScroll + dataStore.headerHeight > (dataStore.scrollWrapTop + dataStore.scrollerDistance )
        // && dataStore.docScroll < (dataStore.processScrollItemHeight + dataStore.scrollWrapTop
        // + dataStore.scrollerDistance + dataStore.headerHeight +20)) {
        //     // PROCESSITEM VERLAESST SICHTBAREN TEIL. LINKER TEIL MIT TRANSLATEN
        //     dataStore.scrollCorrection = false;
        //     var newTranslate =  (dataStore.docScroll + dataStore.scrollerHeight)
        //     - (dataStore.scrollWrapHeight + dataStore.scrollWrapTop - dataStore.headerHeight);

        //     dataStore.scroller.css({
        //         transform: 'translate(0,-'+newTranslate+'px)',
        //         '-webkit-transform': 'translate(0,-'+newTranslate+'px)'
        //     });
        // }

        // if ((dataStore.docScroll < dataStore.scrollWrapTop - dataStore.headerHeight) && dataStore.scrollFixed) {
        //     // FIX RAUS
        //     console.log('fix raus');
        //     $('.processContent.current').removeClass('current');
        //     $('.processContent:first').addClass('current');
        //     $('.processItem.process.current').removeClass('current');
        //     $('.processItem.process:first').addClass('current');
        //     dataStore.scrollFixed = false;
        //     // dataStore.scrollCorrection = false;
        //     // dataStore.scroller.removeClass('fixed');
        //     // dataStore.scroller.css({
        //     //     transform: 'translate(0,0)',
        //     //     '-webkit-transform': 'translate(0,0)'
        //     // });

        //     $('.processContent.current').removeClass('current');
        //     $('.processContent:first').addClass('current');
        //     $('.processItem.process.current').removeClass('current');
        //     $('.processItem.process:first').addClass('current');
        // }

        // if( !(dataStore.docScroll + dataStore.headerHeight > (dataStore.scrollWrapTop + dataStore.scrollerDistance )
        // && dataStore.docScroll < (dataStore.processScrollItemHeight + dataStore.scrollWrapTop
        // + dataStore.scrollerDistance + dataStore.headerHeight +20))
        // && (dataStore.docScroll > dataStore.scrollWrapTop + dataStore.scrollerDistance)
        // && !dataStore.scrollCorrection) {
        //     // LETZTEN TRANSLATEWERT NACHTRAGEN, SCHNELLER SCROLL
        //     var lastTrans = dataStore.docScroll + dataStore.headerHeight
        //- (dataStore.scrollWrapTop + dataStore.scrollerDistance);
        //     // lastTrans wert richtig berechnen, dann denn css hack (.whiteBgWrap) wieder raus nehmen
        //     dataStore.scrollCorrection = true;
        //     dataStore.scroller.css({
        //         transform: 'translate(0,'+lastTrans+'px)',
        //         '-webkit-transform': 'translate(0,'+lastTrans+'px)'
        //     });
        // }
    }
}

/*------------------------ hover line ------------------------*/

function mainNavigation() {
    if (dataStore.touch === false) {
        // navigation hover
        let $el;
        let leftPos;
        let newWidth;

        const homeLink = $('.navWrap li:first a');
        const current = $('.navWrap li.current_page_item, .navWrap li.current_menu_item');

        // if(window.wp_urls.isHome == parseInt(1)){

        //     var pointWidth = homeLink.outerWidth(),
        //         pointLeft = homeLink.position().left,
        //         opacity = 1;

        let pointWidth = 0;
        let pointLeft = homeLink.position().left;
        let opacity = 0;

        if (current.length) {
            pointWidth = current.outerWidth();
            pointLeft = current.position().left;
            opacity = 1;
        }

        dataStore.line
            .width(pointWidth)
            .css('left', pointLeft)
            .css('opacity', opacity)
            .data('origLeft', dataStore.line.position().left)
            .data('origWidth', dataStore.line.width())
            .data('origOpacity', dataStore.line.css('opacity'));

        $('#header-menu > li').hover((e) => {
            $el = $(e.currentTarget).find('a');
            leftPos = $el.parent().position().left;
            newWidth = $el.parent().width();

            // alte werte abspeichern
            dataStore.line
                .data('origLeft', dataStore.line.position().left)
                .data('origWidth', dataStore.line.width())
                .data('origOpacity', dataStore.line.css('opacity'));

            // linie zu gehovertem navi punkt animieren
            dataStore.animationActive = true;

            dataStore.line.stop().animate({
                left: leftPos,
                width: newWidth,
                opacity: 1
            }, {
                duration: 250,
                complete() {
                    dataStore.animationActive = false;
                }
            });
        }, () => {
            if (dataStore.clickScrollActive === false) {
                dataStore.animationActive = true;

                // hier zu aktivem scrollpunkt zurückkehren
                const currentLink = $('.navWrap li').eq(dataStore.scrollNaviCurrentIndex);
                pointWidth = currentLink.outerWidth();
                pointLeft = currentLink.position().left;

                opacity = 0;

                // if(window.wp_urls.isHome == parseInt(1)) {
                //     var opacity = 1;
                // }else{
                //     var opacity = 0;
                // }

                dataStore.line.stop().animate({
                    left: pointLeft,
                    width: pointWidth,
                    opacity
                }, {
                    complete() {
                        dataStore.animationActive = false;
                    }
                });
            }
        });
    }
}

function scrollStacks() {
    if (dataStore.stackScroll === true) {
        // console.log('stack scroll');
        const scaleTarget = 0.05;
        let naviItems;

        for (let a = 0; a < dataStore.stackArr.length; a++) {
            // console.log($(dataStore.stackArr[a].container));

            naviItems = $(dataStore.stackArr[a].container).find('.stackNav li');
            // console.log(naviItems);

            if (dataStore.docScroll + dataStore.winHeight >= dataStore.stackArr[a].top - 90) {
                let scale = 1;
                let itemEntry;
                const objectlength = Object.keys(dataStore.stackArr[a].items).length;
                let scrollNaviItem;

                for (let i = 0; i < objectlength; i++) {
                    itemEntry = dataStore.stackArr[a].items[`stackCard_${a}_${i}`];

                    scale = 1;

                    if (dataStore.docScroll >= itemEntry.top) {
                        // console.log('jetzt scale');

                        if (i + 1 !== objectlength) {
                            scale = 1 - ((scaleTarget / itemEntry.height) * (dataStore.docScroll - itemEntry.top));

                            // console.log(scale);

                            if (scale < 1 - (scaleTarget * (objectlength - i))) {
                                scale = 1 - (scaleTarget * (objectlength - i));
                            }
                        }
                    }

                    if ((dataStore.docScroll >= itemEntry.top
                        && dataStore.docScroll < (itemEntry.top + itemEntry.height))) {
                        // console.log('jetzt active');

                        scrollNaviItem = $(`li[data-target='stackCard_${a}_${i}']`);

                        naviItems.removeClass('current');
                        scrollNaviItem.addClass('current');
                    }

                    $(itemEntry.item).css({
                        transform: `translate(0,${itemEntry.offset}px) scale(${scale})`,
                        '-webkit-transform': `translate(0, ${itemEntry.offset}px) scale(${scale})`
                    });
                }
            }
        }
    }
}
/*
function scrollNavi() {
    /* scroll navi ++++++++++++++++++++++++++++++++++++++++++

    if (dataStore.scrollNavi && dataStore.clickScrollActive == false) {
        let id,
            targetId,
            divTop,
            divMiddle,
            divHeight,
            scrollNavi;

        for (let i = 0, iLen = dataStore.scrollNaviItems.length; i < iLen; i++) {
            divTop = $(`#${dataStore.scrollNaviItems[i]}`).offset().top - dataStore.headerHeight;
            // get the offset of the div from the top of page

            divHeight = $(`#${dataStore.scrollNaviItems[i]}`).height();
            divMiddle = divHeight / 2; // get the height of the div in question

            scrollNavi = $(`a[href*='#${dataStore.scrollNaviItems[i]}']`);

            if ((dataStore.docScroll >= divTop && dataStore.docScroll < (divTop + divHeight))
                && dataStore.lastActiveMenuItem !== i) {
                dataStore.lastActiveMenuItem = i;
                const parent = scrollNavi.closest('li'),
                    leftPos = parent.position().left,
                    newWidth = parent.width();

                dataStore.scrollNaviCurrentIndex = parent.index();

                if (dataStore.animationActive === false) {
                    dataStore.animationActive = true;

                    dataStore.line.stop()
                        .animate({
                            left: leftPos,
                            width: newWidth,
                            opacity: 1
                        }, {
                            duration: 250,
                            complete() {
                                dataStore.animationActive = false;
                            }
                        });
                }

                $('#mainnavCon .menu-item a').removeClass('current');
                scrollNavi.addClass('current');
            }
        }
    }
}
*/

// ---------------------------------------------------- //
// TEAM OWL CAROUSEL
// ---------------------------------------------------- //
function sliderGallery() {
    const gallery = '.boxSlider .slider';
    let slider = false;

    const items = $(gallery).find('.item');
    let minHeight = 0;

    items.each((i, el) => {
        if ($(el).height() > minHeight) {
            minHeight = $(el).height();
        }
    });

    $('.boxSlider').css('min-height', `${minHeight + 20}px`);

    /*** Init ***/
    const init = () => {
        build();

        $(window).on('resize', () => {
            waitForFinalEvent(() => {
                destroy();
            }, 200, 'sliderGallery');
        });
    };

    /*** Build slider ***/
    const build = () => {
        slider = $(gallery).addClass('owl-carousel'); // Add owl slider class (3*)
        slider.owlCarousel({
            loop: true,
            center: true,
            margin: 60,
            nav: true,
            navText: [`<img src='${window.wp_urls.template_dir}/assets/img/arrow-left.svg'>`,
                `<img src='${window.wp_urls.template_dir}/assets/img/arrow-right.svg'>`],
            dots: false,
            autoWidth: true,
            smartSpeed: 400,
            onInitialized(e) {
                if (dataStore.isMobile === false) {
                    const idx = e.item.index;
                    $('.boxSlider .owl-item').eq(idx).addClass('big');
                }
            }
        });

        // if (dataStore.isMobile === false) {
        //     slider.on('drag.owl.carousel', () => {
        //         $('.boxSlider .owl-item.big').removeClass('big');
        //     });

        //     slider.on('translated.owl.carousel', (e) => {
        //         const idx = e.item.index;
        //         $('.boxSlider .owl-item').eq(idx).addClass('big');
        //     });
        // }

        slider.on('translate.owl.carousel', (e) => {
            if (dataStore.isMobile === false) {
                const idx = e.item.index;
                $('.boxSlider .owl-item.big').removeClass('big');
                $('.boxSlider .owl-item').eq(idx).addClass('big');
            }
        });

        // slider.on('drag.owl.carousel', () => {
        //     $('body').css('overflow', 'hidden');
        // });

        // slider.on('dragged.owl.carousel', () => {
        //     $('body').css('overflow', 'hidden auto');
        // });
    };

    /*** Destroy slider ***/
    const destroy = () => {
        // console.log('destroy');
        slider.trigger('destroy.owl.carousel'); // Trigger destroy event (4*)
        slider = false; // Reinit slider variable
        $(gallery).removeClass('owl-carousel'); // Remove owl slider class (3*)
        build();
    };

    init();
}

// ---------------------------------------------------- //
// PREVENT MULTIPLE CALLS
// ---------------------------------------------------- //

const waitForFinalEvent = (() => {
    const timers = {};
    return (callback, ms, uniqueId) => {
        let id = uniqueId;
        if (!uniqueId) {
            id = "Don't call this twice without a uniqueId";
        }
        if (timers[id]) {
            clearTimeout(timers[id]);
        }
        timers[id] = setTimeout(callback, ms);
    };
})();

function loadflipBook() {
    const flipbook = $('#flipbook');
    let itemPages = flipbook.data('count');

    // Check if the CSS was already loaded
    if (flipbook.width() === 0 || flipbook.height() === 0) {
        setTimeout(loadflipBook, 10);
        return;
    }

    // Slider

    // $('#slider').slider({
    //     min: 1,
    //     max: itemPages,
    //     // step: 2,
    //     start(event, ui) {
    //         if (!window._thumbPreview) {
    //             dataStore.thumbPreview = $('<div />', { class: 'thumbnail' }).html('<div></div>');
    //             setPreview(ui.value);
    //             dataStore.thumbPreview.appendTo($(ui.handle));
    //         } else setPreview(ui.value);

    //         // moveBar(true);
    //     },

    //     slide(event, ui) {
    //         setPreview(ui.value);
    //     },

    //     stop() {
    //         if (window._thumbPreview) dataStore.thumbPreview.removeClass('show');

    //         $('#flipbook').turn('page', Math.max(1, $(this).slider('value') * 2 - 2));
    //     }
    // });

    $('#slider').slider({
        min: 1,
        max: itemPages,
        // step: 2,
        start: (event, ui) => {
            if (!window._thumbPreview) {
                window._thumbPreview = $('<div />', { class: 'thumbnail' }).html('<div></div>');
                setPreview(ui.value);
                window._thumbPreview.appendTo($(ui.handle));
            } else { setPreview(ui.value); }
            // moveBar(true);
        },

        slide(event, ui) {
            setPreview(ui.value);
        },

        stop() {
            if (window._thumbPreview) { window._thumbPreview.removeClass('show'); }

            $('#flipbook').turn('page', Math.max(1, $(this).slider('value') * 2 - 2));
        }
    });

    flipbook.turn({
        elevation: 50,
        acceleration: false,
        gradients: true,
        autoCenter: true,
        duration: 1000,
        pages: itemPages,
        when: {

            turning(e, page) {
                const book = $(this);
                const currentPage = book.turn('page');

                /*eslint-disable */
                itemPages = book.turn('pages');
                /*eslint-enable */

                if (currentPage > 3 && currentPage < itemPages - 3) {
                    if (page === 1) {
                        book.turn('page', 2).turn('stop').turn('page', page);
                        e.preventDefault();
                    } else if (page === itemPages) {
                        book.turn('page', itemPages - 1).turn('stop').turn('page', page);
                        e.preventDefault();
                    }
                } else if (page > 3 && page < itemPages - 3) {
                    if (currentPage === 1) {
                        book.turn('page', 2).turn('stop').turn('page', page);
                        e.preventDefault();
                    } else if (currentPage === itemPages) {
                        book.turn('page', itemPages - 1).turn('stop').turn('page', page);
                        e.preventDefault();
                    }
                }
            },

            turned(e, page) {
                const book = $(this);

                $('#slider').slider('value', getViewNumber(book, page));
                // moveBar(true);

                if (page === 1) {
                    $(this).turn('peel', 'br');
                }

                book.turn('center');
            },

            // start(e, pageObj) {
            //     moveBar(true);
            // },

            end() {
                const book = $(this);

                setTimeout(() => {
                    $('#slider').slider('value', getViewNumber(book));
                }, 1);

                // moveBar(false);
            },

            // missing: function (e, pages) {

        //     for (var i = 0; i < pages.length; i++)
        //         addPage(pages[i], $(this));
        // }
        }
    });

    resizeViewport();

    $('#slider').slider('option', 'max', numberOfViews(flipbook));

    // $('#slider').slider('option', 'max', pages/2);
    // $( "#slider" ).slider( "value", 1 );

    flipbook.addClass('animated');
}

function resizeViewport() {
    if (!$('#flipbook').length) return;

    const width = $(window).width() - 30,
        height = $(window).height(),
        options = $('#flipbook').turn('options');

    $('#flipbook').removeClass('animated');

    const bound = calculateBound({
        width: options.width,
        height: options.height,
        boundWidth: Math.min(options.width, width),
        boundHeight: Math.min(options.height, height)
    });

    if (bound.width % 2 !== 0) {
        bound.width -= 1;
    }

    if (bound.width !== $('#flipbook').width() || bound.height !== $('#flipbook').height()) {
        $('#flipbook').turn('size', bound.width, bound.height);

        if ($('#flipbook').turn('page') === 1) $('#flipbook').turn('peel', 'br');

        // $('.next-button').css({height: bound.height, backgroundPosition: '-38px '+(bound.height/2-32/2)+'px'});
        // $('.previous-button').css({height: bound.height, backgroundPosition: '-4px '+(bound.height/2-32/2)+'px'});
    }

    // $('#flipbook').css({top: -bound.height/2, left: -bound.width/2});

    // const magazineOffset = $('#flipbook').offset(),
    //     boundH = height - magazineOffset.top - $('#flipbook').height();

    $('#flipbook').addClass('animated');
}

function numberOfViews(book) {
    return book.turn('pages') / 2 + 1;
}

function getViewNumber(book, page) {
    return parseInt((page || book.turn('page')) / 2 + 1, 10);
}

// function moveBar(yes) {
//     if (Modernizr && Modernizr.csstransforms) {
//         $('#slider .ui-slider-handle').css({ zIndex: yes ? -1 : 10000 });
//     }
// }

function setPreview(viewSet) {
    let view = viewSet;

    if (viewSet > 2) {
        view = viewSet * 2 - 2;
    }

    const pageItem = $(`.thumb_${view}`);
    const previewWidth = pageItem.data('thumbw');
    let previewHeight = pageItem.data('thumbh');
    const preview = $(window._thumbPreview.children(':first'));
    let previewSrc;

    if (pageItem.hasClass('doubleThumb')) {
        previewHeight /= 2;

        const thumb1 = pageItem.find('div').eq(0).data('thumb'),
            thumb2 = pageItem.find('div').eq(1).data('thumb');

        previewSrc = `url(${thumb1}), url(${thumb2})`;
        // const backgroundSize = '50%';
        // const backgroundPos = 'left,right';

        preview.addClass('double');
    } else {
        previewSrc = `url(${pageItem.data('thumb')})`;
        preview.removeClass('double');
    }

    const numPages = (view === 1 || view === $('#slider').slider('option', 'max')) ? 1 : 2,
        width = (numPages === 1 || view === $('#flipbook').data('count')) ? previewWidth / 2 : previewWidth;

    const newHeight = (previewHeight / previewWidth) * width;

    window._thumbPreview
        .addClass('no-transition')
        .css({
            width: width + 15,
            height: newHeight + 15,
            top: -newHeight - 30,
            left: ($($('#slider').children(':first')).width() - width - 15) / 2
        });

    preview.css({
        width,
        height: newHeight
    });

    if (previewSrc !== '') {
        preview.css({ backgroundImage: previewSrc });

        setTimeout(() => {
            window._thumbPreview.removeClass('no-transition');
        }, 0);
    }

    // preview.css({backgroundPosition:
    //     '0px -'+((view-1)*previewHeight)+'px'
    // });
}

function calculateBound(d) {
    const bound = { width: d.width, height: d.height };

    if (bound.width > d.boundWidth || bound.height > d.boundHeight) {
        const rel = bound.width / bound.height;

        if (d.boundWidth / rel > d.boundHeight && d.boundHeight * rel <= d.boundWidth) {
            bound.width = Math.round(d.boundHeight * rel);
            bound.height = d.boundHeight;
        } else {
            bound.width = d.boundWidth;
            bound.height = Math.round(d.boundWidth / rel);
        }
    }

    return bound;
}

function createSvgPaths() {
    if ($('.processItem').length > 1) {
        // Kreutzungen
        const wrap = $('.crossSvgWrap');

        for (let i = 0, iLen = wrap.length; i < iLen; i++) {
            const items = $(wrap[i]).find('.connectionSvg');

            for (let a = 0, aLen = items.length; a < aLen; a++) {
                updateDependencySvg(items[a], a);
            }
        }

        // letzte Elemente nach Kreutzungen zum Endpunkt hin
        const lastItems = $('.crossroad .itemLast .connectionSvg');

        for (let a = 0, aLen = lastItems.length; a < aLen; a++) {
            updateDependencySvg(lastItems[a], a);
        }
    }
}

function updateDependencySvg(svg, i) {
    // console.log(svg);

    const triangle = $(svg).next('.triangle');
    const strokeWidth = 2;
    const offset = strokeWidth / 2;
    let svgHeight = 60;
    let svgWidth = 2;
    let svgLeft;
    let parent = $(svg).parent();

    let p1X = offset;
    let p1Y = offset;
    let p2X = p1X;
    let p2Y = p1Y;
    let p3X = p1X;
    let p3Y = svgHeight - offset;

    let target;

    if (parent.hasClass('itemLast')) {
        if (parent.parent().hasClass('process')) {
            target = $('.processItem.end div');
        } else {
            const li = $(svg).closest('.crossroad');
            target = li.next('li').find('> div');
        }

        const crossCenter = $(parent).offset().left + $(parent).width() / 2;
        const targetWidth = target.width();
        const targetCenter = target.offset().left + targetWidth / 2;

        svgHeight = target.height() / 2 + target.offset().top - (parent.offset().top + parent.height());

        if ((targetCenter - crossCenter) > -2 && (targetCenter - crossCenter) < 2) { // mittig über dem Ziel
            svgHeight = target.offset().top - (parent.offset().top + parent.height());

            svgWidth = 2;
            svgLeft = ($(parent).width() / 2) - 1;

            p1X = offset;
            p1Y = offset;
            p2X = offset;
            p2Y = offset;
            p3X = offset;
            p3Y = svgHeight;

            const top = (parent.parent().height() + svgHeight - 19);

            $(triangle).css({
                left: `${-(svgWidth - $(parent).width() / 2 - 2)}px`,
                top: `${top}px`,
            });
        } else if (targetCenter < crossCenter) { // rechts vorm Ziel
            svgLeft = targetCenter - $(parent).offset().left + targetWidth / 2;
            svgWidth = crossCenter - targetCenter + strokeWidth - targetWidth / 2;

            p1X = svgWidth - offset;
            p1Y = offset;
            p2X = p1X;
            p2Y = svgHeight - offset;
            p3X = offset;
            p3Y = p2Y;

            let top = (parent.parent().height() + svgHeight - 2);

            // if (dataStore.isSafari === true) {
            top += 4;
            // }

            $(triangle).css({
                left: `${-(svgWidth - $(parent).width() / 2 - 11)}px`,
                top: `${top}px`,
                transform: 'rotate(90deg)',
                'margin-left': -1
            });
        } else { // links vorm Ziel
            svgLeft = $(parent).width() / 2;
            svgWidth = targetCenter - crossCenter + strokeWidth - targetWidth / 2;

            p1X = offset;
            p1Y = offset;
            p2X = offset;
            p2Y = svgHeight - offset;
            p3X = svgWidth - offset;
            p3Y = p2Y;

            let top = (parent.parent().height() + svgHeight - 2);

            // if (dataStore.isSafari === true) {
            top += 4;
            // }

            $(triangle).css({
                right: `${-(svgWidth - $(parent).width() / 2 - 11)}px`,
                left: 'auto',
                top: `${top}px`,
                transform: 'rotate(-90deg)',
                'margin-left': 1
            });
        }
    } else {
        parent = svg.closest('.crossroad');
        const parentLi = $(parent).find('> ul > li').eq(i);
        target = $(parentLi).find('> ul > li:first > div');
        const crossCenter = $(parent).width() / 2;
        const targetCenter = target.offset().left + (target.outerWidth() / 2) - $(parent).offset().left;

        if ((targetCenter - crossCenter) > -2 && (targetCenter - crossCenter) < 2) { // mittig über dem Ziel
            svgLeft = targetCenter - offset;
            svgWidth = 2;

            p1X = svgWidth - offset;
            p1Y = offset;
            p2X = offset;
            p2Y = offset;
            p3X = p2X;
            p3Y = svgHeight;

            $(triangle).css({
                left: `${targetCenter}px`,
                top: `${svgHeight - 9}px`,
            });
        } else if (targetCenter < crossCenter) {
            svgLeft = targetCenter - offset;
            svgWidth = crossCenter - targetCenter + strokeWidth;

            p1X = svgWidth - offset;
            p1Y = offset;
            p2X = offset;
            p2Y = offset;
            p3X = p2X;
            p3Y = svgHeight;

            $(triangle).css({
                left: `${targetCenter}px`,
                top: `${svgHeight - 9}px`,
            });
        } else {
            svgLeft = crossCenter;
            svgWidth = targetCenter - crossCenter + strokeWidth;

            p1X = offset;
            p1Y = offset;
            p2X = svgWidth - offset;
            p2Y = offset;
            p3X = p2X;
            p3Y = svgHeight;

            $(triangle).css({
                left: `${targetCenter + 1}px`,
                top: `${svgHeight - 9}px`,
            });
        }
    }

    // console.log(targetCenter);
    // console.log(crossCenter);

    ////// svg anpassen und positionieren //////
    $(svg).attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`).attr('style', `left: ${svgLeft}px; width: ${svgWidth}px;`);

    // ////// polyline anpassen //////

    $(svg).find('polyline').attr({
        points: `${p1X},${p1Y} ${p2X},${p2Y} ${p3X},${p3Y}`
    });
}

function singleScrollResize() {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();
    dataStore.headerHeight = dataStore.header.outerHeight() - 15;
    dataStore.headerOffset = 0;

    if (dataStore.scrollWrap.length && dataStore.docWidth >= dataStore.smMax) {
        dataStore.singleScroll = true;
        dataStore.singleScrollTrans = true;
        dataStore.scrollWrapTop = dataStore.scrollWrap.offset().top; // abstand von oben
        dataStore.scrollerHeight = $('.scrollitem').height();
        dataStore.scrollerOuterHeight = dataStore.scroller.outerHeight(true); // hÃ¶he scrollbares el
        // dataStore.processScrollItemHeight = $('.process.scrollitem').height();

        // dataStore.scrollWrap.css({
        //     'min-height': `${dataStore.scrollerOuterHeight}px`
        // });

        dataStore.scrollWrapHeight = dataStore.scrollWrap.height(); // hÃ¶he gesamt

        // scroll endpunkt
        dataStore.scrollerDistance = dataStore.scrollWrapHeight
        - dataStore.scrollerOuterHeight - dataStore.scrollPuffer;

        // dataStore.scroller.css({
        //     width: dataStore.scrollWrap.outerWidth()
        // });

        // if (dataStore.scrollerOuterHeight + dataStore.scrollPuffer <= dataStore.winHeight
        //     && dataStore.scrollWrapHeight > dataStore.scrollerOuterHeight) {
        //     dataStore.scroller.css({
        //         width: dataStore.scrollWrap.outerWidth()
        //     });
        // } else {
        //     resetScroller();
        // }
    } else {
        resetScroller();
    }

    setTimeout(() => { $(window).trigger('scroll'); }, 100);
}

function resetScroller() {
    dataStore.singleScrollTrans = false;

    $('.processWrapper').addClass('arrows');

    dataStore.scroller.removeClass('fixed').css({
        width: 'auto',
        transform: 'translate(0,0)',
        '-webkit-transform': 'translate(0,0)'
    });
}

function stackResize() {
    // console.log('stackResize()');
    dataStore.stackArr = [];

    $('.stackCardItem').height('auto');

    dataStore.stackContainer.each((i, el) => {
        const wrapTop = $(el).offset().top,
            gap = 64;

        const tempObject = {
            container: $(el),
            top: wrapTop,
            items: {}
        };

        let top;

        const cards = $(el).find('.stackCardItem');

        for (let index = 0; index < cards.length; index++) {
            const element = cards[index],
                id = $(element).attr('id');

            let scale = true;

            if (cards.length === index + 1) {
                scale = false;
            }

            let cardHeight = $(element).height();

            if (index > 0) {
                const prev = tempObject.items[`stackCard_${i}_${index - 1}`],
                    prevHeight = prev.height;

                if (prevHeight > cardHeight) {
                    cardHeight = prevHeight;
                    cards.height(prevHeight);
                    // console.log('set height from bigger element before');
                }

                top = prev.top + prevHeight + gap;
            } else {
                top = wrapTop;
            }

            tempObject.items[id] = {
                item: element,
                top,
                height: cardHeight,
                offset: 30 * index,
                scale
            };

            const fixedTopPos = (dataStore.winHeight - cardHeight) / 2 - dataStore.headerHeight;

            if (fixedTopPos > 80) {
                cards.css('top', fixedTopPos);
            }
        }

        const stacknav = $(el).find('.stackNav'),
            navHeight = stacknav.height(),
            navTop = (dataStore.winHeight - navHeight) / 2;

        // console.log('stacknav');
        // console.log(navHeight);
        // console.log(navTop);

        if (navTop > 80) {
            stacknav.css('top', navTop);
        }

        dataStore.stackArr.push(tempObject);
    });
}

function createJets(rocketManSVG, star, starContainer, jetBubbles, mainSpeedLinesTimeline, mainTimeline) {
    TweenMax.set(jetBubbles, {
        attr: {
            r: '-=5'
        }
    });
    //jet bubbles
    for (let i = 0; i < jetBubbles.length; i++) {
        const jb = jetBubbles[i];
        const tl = new TimelineMax({ repeat: -1 });
        tl.to(jb, 1, {
            attr: {
                r: '+=15'
            },
            ease: Linear.easeNone
        })
            .to(jb, 1, {
                attr: {
                    r: '-=15'
                },
                ease: Linear.easeNone
            });

        mainTimeline.add(tl, i / 4);
    }
    //speed lines
    for (let i = 0; i < 7; i++) {
        const sl = document.querySelector(`#speedLine${i}`);

        const stl = new TimelineMax({ repeat: -1, repeatDelay: Math.random() });

        stl.to(sl, 0.3, { strokeDashoffset: -540, ease: Linear.easeNone });
        mainSpeedLinesTimeline.add(stl, i / 23);
    }
    //stars
    for (let i = 0; i < 7; i++) {
        const sc = star.cloneNode(true);
        starContainer.appendChild(sc);
        const calc = (i + 1) / 2;

        TweenMax.fromTo(sc, calc, {
            x: Math.random() * 600,
            y: -30,
            scale: 3 - calc
        }, {
            y: (Math.random() * 100) + 600,
            repeat: -1,
            repeatDelay: 1,
            ease: Linear.easeNone
        });
    }

    rocketManSVG.removeChild(star);
}

// function animateBubble(bubbleId) {
//     dataStore.bubbleObject[bubbleId].forEach((path) => {
//         const pathConfig = {
//             one: path.attr('d'),
//             two: path.attr('data-path'),
//             three: path.attr('data-path-2')
//         };

//         function animToTwo() {
//             path.animate({
//                 d: pathConfig.two,
//             }, 5000, mina.easeinout, animToThree);
//         }

//         function animToThree() {
//             path.animate({
//                 d: pathConfig.three,
//             }, 5000, mina.easeinout, animToOne);
//         }

//         function animToOne() {
//             path.animate({
//                 d: pathConfig.one,
//             }, 5000, mina.easeinout, animToTwo);
//         }

//         animToTwo();
//     });
// }
