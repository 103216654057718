/*eslint-disable */
/**
 * author Christopher Blum
 *    - based on the idea of Remy Sharp, http://remysharp.com/2009/01/26/element-in-view-event-plugin/
 *    - forked from http://github.com/zuk/jquery.inview/
 */
!function(a) { typeof define === 'function' && define.amd ? define(['jquery'], a) : typeof exports === 'object' ? module.exports = a(require('jquery')) : a(jQuery); }((a) => {
    function i() {
        let b,
            c,
            d = { height: f.innerHeight, width: f.innerWidth }; return d.height || (b = e.compatMode, (b || !a.support.boxModel) && (c = b === 'CSS1Compat' ? g : e.body, d = { height: c.clientHeight, width: c.clientWidth })), d;
    } function j() { return { top: f.pageYOffset || g.scrollTop || e.body.scrollTop, left: f.pageXOffset || g.scrollLeft || e.body.scrollLeft }; } function k() {
        if (b.length) {
            let e = 0,
                f = a.map(b, (a) => {
                    const b = a.data.selector,
                        c = a.$element; return b ? c.find(b) : c;
                }); for (c = c || i(), d = d || j(); e < b.length; e++) {
                if (a.contains(g, f[e][0])) {
                    const h = a(f[e]),
                        k = { height: h[0].offsetHeight, width: h[0].offsetWidth },
                        l = h.offset(),
                        m = h.data('inview'); if (!d || !c) return; l.top + k.height > d.top && l.top < d.top + c.height && l.left + k.width > d.left && l.left < d.left + c.width ? m || h.data('inview', !0).trigger('inview', [!0]) : m && h.data('inview', !1).trigger('inview', [!1]);
                }
            }
        }
    } var c,
        d,
        h,
        b = [],
        e = document,
        f = window,
        g = e.documentElement; a.event.special.inview = { add(c) { b.push({ data: c, $element: a(this), element: this }), !h && b.length && (h = setInterval(k, 250)); }, remove(a) { for (let c = 0; c < b.length; c++) { const d = b[c]; if (d.element === this && d.data.guid === a.guid) { b.splice(c, 1); break; } }b.length || (clearInterval(h), h = null); } }, a(f).on('scroll resize scrollstop', () => { c = d = null; }), !g.addEventListener && g.attachEvent && g.attachEvent('onfocusin', () => { d = null; });
});
/*eslint-enable */
